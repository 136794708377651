<template>
  <div class="bannerTitle">
    <img :src="list[type][2]" alt="" />
    <div v-if="list[type][0] == '亦信科技' " class="banTitleBox">
      <p style="position:relative;margin-left:42px;" class="title"><img class="icon" :src="icon" alt="">{{ list[type][0] }}</p>
      <p
        :class="$i18n.locale == 'zh' ? 'zhTitle' : ''"
        v-if="$i18n.locale == 'zh' || list[type][3]"
      >
        {{ list[type][1] }}
      </p>
    </div>
    <div v-else class="banTitleBox">
      <p class="title">{{ list[type][0] }}</p>
      <p
        :class="$i18n.locale == 'zh' ? 'zhTitle' : ''"
        v-if="$i18n.locale == 'zh' || list[type][3]"
      >
        {{ list[type][1] }}
      </p>
    </div>
    <img src="@/assets/images/1.png" alt="" class="bgBottom" />
  </div>
</template>

<script>
import bg5 from "@/assets/images/yx-bg1.png"
import bg4 from "@/assets/images/bg4.png";
import bg3 from "@/assets/images/bg3.png";
import bg2 from "@/assets/images/bg2.png";
// import bg1 from "@/assets/images/bg1.png";
import bg6 from "@/assets/images/bg6.jpg";
import icon from "@/assets/images/v2/yx-logo.png"
export default {
  name: "bannerTitle",
  props: ["title"],
  data() {
    return {
      type: this.title,
      icon:icon
    };
  },
  computed: {
    list() {
      const data = {
        taitan: [this.$t("tt.banTitle"), this.$t("tt.banTips"), bg4, true],
        linyuan: [this.$t("ly.banTitle"), this.$t("ly.banTips"), bg4, true],
        xianhou: [this.$t("xh.banTitle"), this.$t("xh.banTips"), bg4, true],
        qianfanyun: [
          this.$t("qfy.banTitle"),
          this.$t("qfy.banTips"),
          bg4,
          true
        ],
        tianqin: [this.$t("tq.banTitle"), this.$t("tq.banTips"), bg4, true],
        sheshou: [this.$t("ss.banTitle"), this.$t("ss.banTips"), bg4, true],
        baolaidian: [
          this.$t("bld.banTitle"),
          this.$t("bld.banTips"),
          bg4,
          true
        ],
        xlaidian: [
          this.$t("bld.XbanTitle"),
          this.$t("bld.XbanTips"),
          bg4,
          true
        ],
        qushouru: [this.$t("qsr.banTitle"), this.$t("qsr.banTips"), bg4, true],
        zhijuyun: [this.$t("zjy.banTitle"), this.$t("zjy.banTips"), bg4, true],
        contact: [this.$t("contact.banTitle"), "CONTACT", bg3, false],
        news: ["新闻动态", "NEWS", bg2, false],
        about: [this.$t("about.banTitle"), "", bg6, false],

        // pl
        cili: [this.$t("cili.banTitle"), this.$t("cili.banTips"), bg4, true],
        juliang: [this.$t("juliang.banTitle"), this.$t("juliang.banTips"), bg4, true],
        fyl: [this.$t("fyl.banTitle"), this.$t("fyl.banTips"), bg5, true],
        ylb: [this.$t("ylb.banTitle"), this.$t("ylb.banTips"), bg4, true],
        kxxxd: [this.$t("kxxxd.banTitle"), this.$t("kxxxd.banTips"), bg4, true],
      };
      return data;
    }
  }
};
</script>

<style lang="scss">
.bannerTitle {
   position: relative;
   img {
     width: 100%;
   }
  .banTitleBox {
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;

     .icon {
        width: 43px;
        height: 51px;
        position: absolute;
        margin-left: -48px;
        top: 9px;
      }
    .title {
      height: 65px;
      font-size: 50px;
      font-family: YouSheBiaoTiHei;
      color: rgba(255, 255, 255, 1);
      line-height: 65px;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 28px;
    }
    .zhTitle {
      letter-spacing: 10px;
    }
  }
  .bgBottom {
    position: absolute;
    bottom: 0;
    z-index: 99;
    left: 0;
  }
}
</style>
