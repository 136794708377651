<!--
 * @Editors: pan long
 -->
<template>
  <div>
    <!-- <Header title="kxxxd"></Header> -->
    <BannerTitle title="kxxxd"></BannerTitle>
    <div class="bldBox">
      <div class="introduce wow slideInUp">{{ $t("kxxxd.desc") }}</div>
      <div class="upLine">{{ $t("kxxxd.will") }}</div>
      <ul class="wow slideInUp">
        <li>
          <img src="@/assets/images/game/1.png" alt="" />
          <span>{{ $t("kxxxd.tips1") }}</span>
        </li>
        <li>
          <img src="@/assets/images/game/2.png" alt="" />
          <span>{{ $t("kxxxd.tips2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/game/3.png" alt="" />
          <span>{{ $t("kxxxd.tips3") }}</span>
        </li>
      </ul>
      <div class="show wow slideInUp">
        <div>
          <img style="width:320px;height:666px;" src="@/assets/images/game/4.png" alt="" />
          <span>{{ $t("kxxxd.tips4") }}</span>
        </div>
        <div>
          <img style="width:320px;height:666px;" src="@/assets/images/game/5.png" alt="" />
          <span>
            {{ $t("kxxxd.tips5") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
// import Footer from "../../components/footer.vue";
// import Hotline from "../../components/hotline.vue";
export default {
  name: "baolaidian",
  components: {
    BannerTitle,
    // Header,
    // Footer,
    // Hotline
  }
};
</script>

<style lang="scss">
.bldBox {
  width: 1000px;
  margin: auto;
  .introduce {
    font-size: 16px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
    text-align: center;
  }
  .upLine {
    width:240px;
    height:60px;
    background:rgba(241,241,241,1);
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(34,34,34,1);
    line-height: 60px;
    text-align: center;
    margin: 50px auto;
  }
  ul {
    margin-top: 107px;
    display: flex;
    margin-bottom: 112px;
    justify-content: space-between;
    li {
      width: 270px;
      img {
        width: 72px;
        height: 72px;
        display: block;
        margin: auto;
      }
      span {
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 33px;
        font-size: 20px;
        text-align: center;
        display: block;
        margin-top: 20px;
      }
    }
  }
  .show {
    clear: both;
    overflow: hidden;
    padding: 0 93px;
    margin-bottom: 112px;
    div {
      width: 310px;
      float: left;
      span {
        // text-align: center;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
        margin-top: 25px;
        display: block;
        margin-left: 45px;
      }
    }
    div:first-child {
      margin-right: 190px;
    }
  }
}
</style>
